<template>
  <div class="form-cont">
    <h3 class="form-title">预约老师一对一咨询</h3>
    <div class="form">
      <el-form name="form1" class="form1" ref="ruleForm" :model="formValidate" :rules="ruleValidate">
        <el-form-item prop="mobile">
          <el-input type="text" placeholder="请输入您的电话" v-model="formValidate.mobile" id="tel"/>
        </el-form-item>
        <el-form-item prop="name">
          <el-input type="text" placeholder="请输入姓名" v-model="formValidate.name" id="name"/>
        </el-form-item>
        <el-form-item prop="major">
          <el-select v-model="formValidate.major" placeholder="请选择项目" id="trade">
            <el-option v-for="(item,index) in tradeList" :key="index" :value="item.value">{{ item.value }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini"   @click="submitForm('ruleForm')" class="btn" id="btn">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import {getInsert, getTradeList} from "@/api/data";

export default {
  name: 'FooterForm',
  data() {
    return {
      tradeList: [],
      formValidate: {
        name: "",
        mobile: "",
        major: "",
      },
      ruleValidate: {
        name: [{
          message: '请输入姓名',
          trigger: 'blur',
          required: true,
        }],
        mobile: [{
          required: true,
          message: '请输入正确的手机号',
          trigger: 'blur',
          pattern: '^1(3|4|5|7|8)\\d{9}$'
        }],
        major: [{
          message: '请选择行业',
          required: true,
          trigger: 'blur',
        }],
      },
    }
  },
  methods: {
    submitForm(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.insertAdvice();
        } else {
          this.$message.error('请正确添加信息!');
        }
      })
    },
    async fetchCourseCategories() { // 获取行业
      let params = {
        dictType: "project_type",
        pageSize: "1000",
        pageNum: "1"
      }
      try {
        let {data} = await getTradeList(params);
        this.tradeList = data.content;
      } catch (err) {
        console.log(err);
      }
    },
    async insertAdvice() { //保存数据接口
      try {
        let data = await getInsert(this.formValidate);
        if (data.code == 1000) {
          this.$message.success("提交成功");
          this.$refs['ruleForm'].resetFields();
        } else {
          this.$message.error("该手机号已存在");
        }
      } catch (err) {
        console.log("服务器异常", err);
      }
    },
  },
  mounted() {
    this.fetchCourseCategories();
  },
}
</script>
<style scoped lang="less">
.form-cont {
  height: 350px;
  background: hsla(0, 0%, 100%, .27);
  border-radius: 4px;
  padding-top: 20px;
  text-align: center;
  .form-title{
    padding-top: 15px;
    font-size: 20px;
    text-align: center;
    color: #e58111;
    font-weight: 700;
  }
  .form{
    width: 200px;
    text-align: center;
    margin: 20px auto;
  }

  .el-input, .el-select, .el-textarea {
    width: 100%;
    height: 32px;
  }

  /deep/ .el-input__inner {
    height: 32px;
    font-size: 12px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    color: #515a6e;
    background-color: #fff;
    background-image: none;
  }

  .btn {
    width: 96px;
    padding: 10px;
    background: #e58111;
    border-radius: 5px;
    border: none;
    color: #fff;
    text-align: center;
  }
}
</style>
