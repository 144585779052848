import router from './router'
import store from './store'
import Vue from 'vue'


router.beforeEach((to, from, next) =>{
  // if(to.path === from.path) {
  //   next(false)
  // }
  if(to.meta.requireAuth){
    if(store.state.user.token){
      next()
    } else {
      // next({
      //   replace: true,
      //   path: from.path,
      //   query: from.query,
      // })
      next()
      console.log('Vue', Vue.prototype.$bus.$emit('login'))
      // Vue.$bus.$emit('login')
    }}else {
      next()
    }
})

