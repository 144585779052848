import { render, staticRenderFns } from "./ChatWindow.vue?vue&type=template&id=53451fdd&scoped=true"
import script from "./ChatWindow.vue?vue&type=script&lang=js"
export * from "./ChatWindow.vue?vue&type=script&lang=js"
import style0 from "./ChatWindow.vue?vue&type=style&index=0&id=53451fdd&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53451fdd",
  null
  
)

export default component.exports