import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutVue from "@/layout/index.vue"
import store from "@/store"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Layout',
        component: LayoutVue,
        redirect: '/home',
        children: [
            {
                path: 'home',
                name: 'home',
                meta: {
                    title: '首页',
                    requireAuth: false
                },
                component: () => import('@/views/home/index.vue')
            },
            {
                  path: 'alumni',
                  name: 'alumni',
                  meta: {
                    title: '校友会',
                    requireAuth: false
                  },
                  component: () => import('@/views/alumni/index.vue')
            },
            {
                path: 'schoolProfile',
                name: 'schoolProfile',
                meta: {
                    title: '学校概况',
                    requireAuth: false
                },
                component: () => import('@/views/school-profile/index.vue')
            },
            {
                path: 'teacherTroop',
                name: 'teacherTroop',
                meta: {
                    title: '师资队伍',
                    requireAuth: false
                },
                component: () => import('@/views/teacher-troop/index.vue')
            },
            {
                path: 'marketPage',
                name: 'marketPage',
                meta: {
                    title: '直营中心',
                    requireAuth: false
                },
                component: () => import('@/views/market-page/index.vue')
            },
            {
                path: 'consultPage',
                name: 'consultPage',
                meta: {
                    title: '招生就业-合作咨询',
                    requireAuth: false
                },
                component: () => import('@/views/consult-page/index.vue')
            },
            {
                path: 'cooperationPage',
                name: 'cooperationPage',
                meta: {
                    title: '授权合作',
                    requireAuth: false
                },
                component: () => import('@/views/cooperation-page/index.vue')
            },
            {
                path: 'enterprisePage',
                name: 'enterprisePage',
                meta: {
                    title: '企业招聘',
                    requireAuth: false
                },
                component: () => import('@/views/enterprise-page/index.vue')
            },
            {
                path: 'innovation',
                name: 'innovation',
                meta: {
                    title: '创新与实践',
                    requireAuth: false
                },
                component: () => import('@/views/innovation-practice/index.vue')
            },
            {
                path: 'bbsNews',
                name: 'bbsNews',
                meta: {
                    title: '新闻资讯',
                    requireAuth: false
                },
                component: () => import('@/views/bbs-news/index.vue')
            },
            {
                path: 'newsDetail',
                name: 'newsDetail',
                meta: {
                    title: '新闻资讯详情',
                    requireAuth: false
                },
                component: () => import('@/views/bbs-news/newsDetail.vue')
            },
            {
                path: 'alumniStyle',
                name: 'alumniStyle',
                meta: {
                    title: '校友风采',
                    requireAuth: false
                },
                component: () => import('@/views/alumni-style/index.vue')
            },
            {
                path: 'alumniDetail',
                name: 'alumniDetail',
                meta: {
                    title: '新闻资讯详情',
                    requireAuth: false
                },
                component: () => import('@/views/alumni-style/alumniDetail.vue')
            },
            {
                path: 'undergraduateEdu',
                name: 'undergraduateEdu',
                meta: {
                    title: '人才培养-本科教育',
                    requireAuth: false
                },
                component: () => import('@/views/college-education/index.vue')
            },
            {
                path: 'eduProgram',
                name: 'eduProgram',
                meta: {
                    title: '人才培养-硕士项目',
                    requireAuth: false
                },
                component: () => import('@/views/master-education/index.vue')
            },
            {
                path: 'eduProgramDr',
                name: 'eduProgramDr',
                meta: {
                    title: '人才培养-博士项目',
                    requireAuth: false
                },
                component: () => import('@/views/edu-program-dr/index.vue')
            }
            //     {
            //       path: 'competitionGuide',
            //       name: 'competitionGuide',
            //       meta: {
            //         title: '竞赛指南',
            //         requireAuth: false
            //       },
            //       component: () => import('@/views/competitionGuide/index.vue')
            //     }, {
            //       path: 'workDisplay',
            //       name: 'workDisplay',
            //       meta: {
            //         title: '作品展示',
            //         requireAuth: false
            //       },
            //       component: () => import('@/views/workDisplay/index.vue')
            //     }, {
            //       path: 'workDisplay/detail/:id',
            //       name: 'workDisplayDetail',
            //       meta: {
            //         activePath: '/workDisplay',
            //         title: '作品展示详情',
            //         requireAuth: false
            //       },
            //       component: () => import('@/views/workDisplay/detail.vue')
            //     }, {
            //       path: 'listOfWinners',
            //       name: 'listOfWinners',
            //       meta: {
            //         title: '获奖名单',
            //         requireAuth: true
            //       },
            //       component: () => import('@/views/listOfWinners/index.vue')
            //     }, {
            //       path: 'organizationalStructure',
            //       name: 'organizationalStructure',
            //       meta: {
            //         title: '组织机构',
            //         requireAuth: false
            //       },
            //       component: () => import('@/views/organizationalStructure/index.vue')
            //     }, {
            //       path: 'competitionGuide/detail/:id',
            //       name: 'competitionGuideDetail',
            //       meta: {
            //         activePath: '/competitionGuide',
            //         title: '新闻详情',
            //         requireAuth: false
            //       },
            //       component: () => import('@/views/competitionGuide/detail.vue')
            //     }, {
            //       path: 'academicActivities',
            //       name: 'academicActivities',
            //       meta: {
            //         title: '学术活动',
            //         requireAuth: false
            //       },
            //       component: () => import('@/views/academicActivities/index.vue')
            //     }, {
            //       path: 'researchFindings',
            //       name: 'researchFindings',
            //       meta: {
            //         title: '研究成果',
            //         requireAuth: false
            //       },
            //       component: () => import('@/views/researchFindings/index.vue')
            //     }, {
            //       path: 'sciencePopularizationActivities',
            //       name: 'sciencePopularizationActivities',
            //       meta: {
            //         title: '科普活动',
            //         requireAuth: false
            //       },
            //       component: () => import('@/views/sciencePopularizationActivities/index.vue')
            //     }, {
            //       path: 'societyMembers',
            //       name: 'societyMembers',
            //       meta: {
            //         title: '学会成员',
            //         requireAuth: false
            //       },
            //       component: () => import('@/views/societyMembers/index.vue')
            //     }, {
            //       path: 'aboutUs',
            //       name: 'aboutUs',
            //       meta: {
            //         title: '关于我们',
            //         requireAuth: false
            //       },
            //       component: () => import('@/views/aboutUs/index.vue')
            //     }, {
            //       path: 'contactUs',
            //       name: 'contactUs',
            //       meta: {
            //         title: '联系我们',
            //         requireAuth: false
            //       },
            //       component: () => import('@/views/contactUs/index.vue')
            //     }, {
            //       path: 'honor',
            //       name: 'honor',
            //       meta: {
            //         title: '学会荣誉',
            //         requireAuth: false
            //       },
            //       component: () => import('@/views/honor/index.vue')
            // }
        ]
    }
]
const router = new VueRouter({
    routes,
    scrollBehavior: () => ({y: 0, smooth: true}),
    mode: 'history'
})

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
let routerReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location) {
    return routerReplace.call(this, location).catch(err => err)
}


export default router
