<template>
  <div class="chat-window">
    <div class="chat-header">
      <img src="@/assets/consult-image.png" alt="" class="avatar" @click="openExternalLink">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    openExternalLink() {
      window.open("https://ada.baidu.com/site/wjzik1q1/agent?imid=fe92ff77ed0a07c9f281242eecb969f0");
    },
  }
};
</script>

<style scoped lang="less">
.chat-window {
  position: fixed;
  bottom: 50px;
  right: 5px;
  z-index: 900;
  cursor: pointer;
  width: 137px;
  height: 135px;
}

.avatar {
  width: 100%;
  height: auto;
  image-rendering: pixelated

}

.el-icon-close {
  cursor: pointer;
  margin-left: auto;
}



</style>
