import axios from "axios";
import qs from "qs";
import store from "@/store";

const service = axios.create({
  baseURL: '/',
  // timeout: 20000, // 设置超时时长
});

// 请求拦截器
service.interceptors.request.use(config => {
  // if(getToken()) {
  //   config.headers['Authorization'] = 'berar ' + getToken()
  // }
  if (config.headers["Content-Type"] == "application/x-www-form-urlencoded; charset=utf-8") {
    config.data = qs.stringify(config.data);
  }
  return config;
})


// 响应拦截器
service.interceptors.response.use(response => {
  console.log("================",response.data.code)
  if (response.data.code === 200 || response.data.code === 1000) {
    if (response.data instanceof Blob) {
      return response.data;
    }
    //  else if (response.data.code != 1000) {
    //   return Promise.reject(response.data.msg);
    // }
    else {
      return response.data;
    }
  } else {
    return Promise.reject(response.data.msg);
  }
}, function (error) {
  return Promise.reject(error);
})

class http {
  static get(url, params = {}, headers = {}) {
    return service.get(url, { params, headers });
  }
  static post(url, data = {}, headers = {}) {
    return service.post(url, data, { headers });
  }
  static postBody(
    url,
    data = {},
    headers = {
      "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
    }
  ) {
    return service.post(url, data, { headers });
  }
  static upFile(url, file) {
    return service.post(url, file, {
      headers: { "Content-Type": false },
    });
  }
  static downFile(url, params = {}) {
    return service.post(url, params, {
      responseType: "blob",
    });
  }
}

export default http;
