<template>
  <el-container class="layout">
<!--    <header-vue></header-vue>-->
    <el-main>
      <router-view></router-view>
    </el-main>
    <el-footer>
      <footer-vue></footer-vue>
    </el-footer>
  </el-container>
</template>

<script>
import FooterVue from "./component/FooterVue.vue";
// import HeaderVue from "./component/HeaderVue";

export default {
  name: "layOut",
  components: {
    // HeaderVue,
    FooterVue,
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-main {
  overflow: inherit;
  min-height: calc(~"100vh - 130px");
  padding: 0;
  background-color: transparent;
  position: relative;
}

/deep/ .el-main::-webkit-scrollbar {
  display: none;
}

/deep/ .el-header {
  z-index: 1000;
  top: 0;
  padding: 0 !important;
}

/deep/ .el-footer {
  padding: 0 !important;
  height: 70px !important;
}
</style>
