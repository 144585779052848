<template>
  <div id="app">
    <router-view></router-view>
    <el-backtop :visibility-height="240" :right="20" :bottom="200"></el-backtop>
    <ChatWindow />
  </div>
</template>

<script>
import ChatWindow from './components/ChatWindow.vue';

export default {
  name: 'app',
  components: {
    ChatWindow
  },
  data () {
    return {

    }
  }
}
</script>
<style>
</style>
