<template>
  <div class="header-cont">
    <div class="header">
      <div class="header-box">
        <!--  logo -->
        <div class="logo-item">
          <img src="../../assets/logo.png" alt="" @click="goBack()"/>
        </div>
        <!--  logo -->
        <div class="header-right">
          <div class="header-top">
            <a class="" href="http://user.yzvet.com/" target="_blank">在线学习</a>
            <a href="https://brest-bs.com/" target="_blank">法国官网</a>
            <p class="last">中国区官网</p>
          </div>
          <!--  菜单栏 -->
          <el-menu :default-active="activeIndex" mode="horizontal" class="el-menu-demo" @select="handleSelect">
            <template v-for="item in menuList">
              <el-submenu v-if="item.children && item.children.length" :index="item.id" :key="item.id">
                <template slot="title">{{ item.title }}</template>
                <el-menu-item v-for="child in item.children" :key="child.id" :index="child.id">{{
                    child.title
                  }}
                </el-menu-item>
              </el-submenu>
              <el-menu-item v-else :index="item.id" :key="item.id">{{ item.title }}</el-menu-item>
            </template>
          </el-menu>
          <!--  菜单栏 -->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "HeaderMenu",
  data() {
    return {};
  },
  watch: {
    // 监听 vuex 中 selectMenu 的变化
    selectMenu: {
      handler(newValue, oldValue) {
        console.log('header selectMenu数据变化:', newValue, oldValue);
        // 数据置空时，不执行后续逻辑
        if (!newValue) return;
        if (!newValue.path) return;
        if (newValue.path === oldValue.path && newValue.id === oldValue.id) {
          return
        }
        // 数据发生了有效变化
        console.log("变化有效，触发路由跳转")
        this.$router.push({
          path: newValue.path,
          query: {id: newValue.id}
        });
      },
      // 对象或数组需设置 deep 为 true
      deep: true
    },
  },
  computed: {
    ...mapState({
      // 映射Vuex中的状态
      selectMenu: state => state.menu.selectMenu,
      menuList: state => state.menu.headerMenuList,
    }),
    activeIndex() {
      if (this.selectMenu) {
        return this.selectMenu.id;
      }
      return this.$route?.meta?.activePath || this.$route.path;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    /** 点击导航返回首页 */
    goBack() {
      this.$router.push({path: "/home"});
    },
    /** 下滑事件添加class */
    handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 0) {
        document.querySelector('.header').classList.add('box-active');
      } else {
        document.querySelector('.header').classList.remove('box-active');
      }
    },
    // 点击菜单栏, 更改 vuex 数据
    handleSelect(key) {
      const menu = this.findMenu(this.menuList, key);
      console.log("menu ====", menu)
      this.$store.commit('setSelectMenu', key);
    },
    findMenu(items, key) {
      for (const item of items) {
        if (item.id === key) {
          return item;
        }
        if (item.children) {
          const menu = this.findMenu(item.children, key);
          if (menu) {
            return menu;
          }
        }
      }
      return null;
    }
  },
};
</script>


<style lang="less" scoped>
.box-active {
  background: url("@/assets/head-bg.png") no-repeat;
  background-size: 100%;
  height: 105px;
}
.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  .header-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 1200px;
    //padding: 0 80px;
    margin: 0 auto;
    .logo-item{
      width: 350px;
      img{
        width: 100%;
      }
    }

    .header-right {
      text-align: right;

      .header-top {
        display: flex;
        justify-content: right;
        margin: 10px 0;

        p , a{
          margin: 0 11px;
          font-size: 16px;
          color: #fff;

          &:after {
            margin-top: 4px;
            content: '';
            position: absolute;
            display: inline-block;
            vertical-align: middle;
            width: 1px;
            height: 15px;
            background: #fff;
            margin-left: 10px;
          }
        }
        a{
          &:hover{
            color: #e58111;
          }
        }
        .last {
          &:after {
            width: inherit;
          }
        }
      }
    }
  }

  ::v-deep .el-menu.el-menu--horizontal {
    border-bottom: 0;
  }

  ::v-deep .el-menu {
    background: transparent;
  }

  ::v-deep .el-menu-item {
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    color: #fff;
    padding: 0 10px;

    &:hover {
      background: transparent !important;
      color: #e58111 !important;
    }
  }

  ::v-deep .el-submenu.is-active .el-submenu__title{
    border-bottom: none;
  }
  ::v-deep .el-submenu__title {
    height: 30px !important;
    line-height: 30px !important;
    font-size: 18px !important;
    color: #fff !important;
    padding: 0 10px;

    &:hover {
      background: transparent !important;
      color: #e58111 !important;
    }
  }
  ::v-deep .el-menu-item.is-active {
    border-bottom: inherit !important;
    color: #e58111 !important;
    background: inherit !important;
  }
  ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
    display: none !important;
  }
}
</style>
