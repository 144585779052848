<template>
  <div class="form-cont">
    <h3 class="form-title">投诉与建议</h3>
    <div class="form">
      <el-form ref="ruleForm" :model="formValidate" :rules="ruleValidate" class="form1">
        <el-form-item prop="name">
          <el-input placeholder="请输入姓名" v-model="formValidate.name"></el-input>
        </el-form-item>

        <el-form-item prop="mobile">
          <el-input placeholder="请输入您的电话" v-model="formValidate.mobile"></el-input>
        </el-form-item>

        <el-form-item prop="major">
          <el-select v-model="formValidate.major" placeholder="请选择专业">
            <el-option
                v-for="(item,index) in courseCategoryList"
                :key="index"
                :label="item.majorName"
                :value="item.majorName">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="message">
          <el-input type="textarea" placeholder="留言" v-model="formValidate.message"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="mini" @click="submitForm('ruleForm')" class="btn">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import {
  getAdviceInsert,
  courseCategory,
} from "@/api/data.js";

export default {
  name: 'AdviceForm',
  data() {
    return {
      courseCategoryList: [],
      formValidate: {
        name: "",
        mobile: "",
        major: "",
        message: "",
      },
      ruleValidate: {
        name: [{
          message: '请输入姓名',
          trigger: 'blur',
          required: true,
        }],
        mobile: [{
          required: true,
          message: '请输入正确的手机号',
          trigger: 'blur',
          pattern: '^1(3|4|5|7|8)\\d{9}$'
        }],
        message: [{
          message: '请输入留言',
          trigger: 'blur',
          required: true,
        }],
        major: [{
          message: '请选择专业',
          required: true,
          trigger: 'change',
        }],
      },
    }
  },
  methods: {
    submitForm(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.insertAdvice();
        } else {
          this.$message.error('请正确添加信息!');
        }
      })
    },
    async fetchCourseCategories() {
      let params = {
        pageSize: "1000",
        pageNum: "1"
      };
      try {
        let {data} = await courseCategory(params);
        this.courseCategoryList = data.content;
      } catch (err) {
        console.log(err);
      }
    },
    async insertAdvice() {
      try {
        let data = await getAdviceInsert(this.formValidate);
        if (data.code == 1000) {
          this.$message.success("保存成功！");
          this.resetForm();
        } else {
          this.$message.error("该手机号已存在");
        }
      } catch (err) {
        console.log("服务器异常", err);
      }
    },
    resetForm() {
      this.formValidate = {
        name: "",
        mobile: "",
        major: "",
        message: "",
      }
    }
  },
  mounted() {
    this.fetchCourseCategories();
  },
}
</script>
<style scoped lang="less">
.form-cont {
  height: 350px;
  background: hsla(0, 0%, 100%, .27);
  border-radius: 4px;
  padding-top: 20px;
  text-align: center;

  .form-title {
    padding-top: 15px;
    font-size: 20px;
    text-align: center;
    color: #e58111;
    font-weight: 700;
  }

  .form {
    width: 200px;
    text-align: center;
    margin: 0 auto;
  }

  .el-input, .el-select, .el-textarea {
    width: 100%;
    height: 32px;
  }

  /deep/ .el-input__inner {
    height: 32px;
    font-size: 12px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    color: #515a6e;
    background-color: #fff;
    background-image: none;
  }
  /deep/ .el-textarea__inner{
    font-size: 12px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    color: #515a6e;
  }
  .btn {
    margin-top: 10px;
    width: 96px;
    padding: 10px;
    background: #e58111;
    border-radius: 5px;
    border: none;
    color: #fff;
    text-align: center;
  }
}
</style>
