<template>
  <div class="footer">
    <div class="footer-cont">
      <!-- 预约老师一对一咨询 -->
      <div class="footer-cont-item">
        <FooterForm/>
      </div>
      <!-- 法国学校信息 -->
      <div class="footer-cont-item" style="flex:1.5">
        <h3 class="school-title">法国学校信息</h3>
        <div class="school-info">
          <p class="address">2 avenue de Provence Brest France</p>
          <p class="address">法国 布雷斯特市 普罗旺斯大街2号</p>
          <p class="phone">02.98.34.44.44</p>
          <p class="mail">contact@brest-bs.com</p>
          <p class="website">www.brest-bs.com</p>
        </div>
        <!-- 公众号二维码 -->
        <div class="qr-item">
          <img src="@/assets/images/bbs-erweima.jpg" class="qr-image" alt="BBS公号"/>
          <p class="qr-text">微信公众号</p>
        </div>
      </div>
      <!-- 中国办事处信息 -->
      <div class="footer-cont-item" style="flex:1.7">
        <h3 class="school-title">中国办事处信息： 中国区官网网址</h3>
        <div class="office-info">
          <p class="address">北京市海淀区学院南路12号-北京师范大学京师科技大厦A座13层</p>
          <p class="phone">13699291394</p>
          <p class="mail">contact.china@brest-bs.com</p>
          <p class="website">www.brest-bs.com.cn</p>
        </div>
      </div>
      <!-- 投诉与建议 -->
      <div class="footer-cont-item">
        <AdviceForm/>
      </div>
    </div>
    <div class="copyright">
      <div class="friend-links">
        <ul>
          <li class="links-title">友情链接：</li>
          <li><a href="https://www.wdecloud.com/" target="_blank">伟东云教育集团</a></li>
          <li><a href="https://jsj.moe.gov.cn/" target="_blank">教育部涉外监管网</a></li>
          <li><a href="http://www.yzvet.com/" target="_blank">国际硕博</a></li>
        </ul>
      </div>
      <p>Copyright © 1962 - 2025 <a href="https://brest-bs.com/" target="_blank" class="link01">BREST BUSINESS SCHOOL
        布雷斯特高等商学院</a> <a href="https://beian.miit.gov.cn/" target="_blank" class="link02">版权所有
        蜀ICP备18014873号</a>
        成都伟东海纳易学教育科技有限公司</p>
    </div>
  </div>
</template>

<script>
import AdviceForm from "@/layout/component/AdviceForm.vue";
import FooterForm from "@/layout/component/FooterForm.vue";

export default {
  name: "FooterVue",
  components: {FooterForm, AdviceForm},
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="less">
.footer {
  background: url("../../assets/images/footer-background.png") no-repeat;
  width: 100%;
  background-size: 100% 100%;
  height: 590px;
}

.footer-cont {
  display: flex;
  justify-content: space-between;
  //padding: 20px 80px;
  padding: 30px 0;
  width: 1200px;
  margin: 0 auto;
}

.footer-cont-item {
  flex: 1.2;
  margin: 0 20px;

  .school-title {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 20px;
  }

  .school-info, .office-info {
    p {
      font-weight: 400;
      color: #fff;
      line-height: 30px;
      font-size: 16px;
      letter-spacing: .5px;
      margin: 10px 0;
    }
  }

  .qr-item {
    width: 100px;
    margin-top: 30px;

    .qr-image {
      width: 100px;
      height: 100px;
    }

    .qr-text {
      color: #fff;
      text-align: center;
      margin-top: 10px;
      font-size: 12px;
    }
  }

}

.school-info, .office-info {
  text-align: left;
  color: #333;
}
.address{
  &:before{
    content: '';
    display: inline-block;
    width: 11px;
    height: 15px;
    background: url("@/assets/images/footer/address.png") no-repeat;
    vertical-align: middle;
    margin-right: 5px;
  }
}
.phone{
  &:before{
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url("@/assets/images/footer/phone.png") no-repeat;
    vertical-align: middle;
    margin-right: 5px;
  }
}
.mail{
  &:before{
    content: '';
    display: inline-block;
    width: 16px;
    height: 12px;
    background: url("@/assets/images/footer/email.png") no-repeat;
    vertical-align: middle;
    margin-right: 5px;
  }
}
.website{
  &:before{
    content: '';
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url("@/assets/images/footer/website.png") no-repeat;
    vertical-align: middle;
    margin-right: 5px;
  }
}
.copyright {
  text-align: center;
  color: #fff;
  font-size: 16px;

  .friend-links {
    margin-bottom: 20px;

    ul {
      .links-title {
        margin-right: 20px;
      }

      li {
        margin-right: 20px;
        display: inline-block;

        a {
          color: #fff;

          &:hover {
            color: #e58111;
          }
        }
      }
    }
  }

  p {
    a {
      color: #fff;

      &:hover {
        color: #e58111;
      }
    }

    .link01 {
      margin: 0 10px;
    }

    .link02 {
      margin-right: 10px;
    }
  }
}
</style>
