import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUi from "element-ui"
import HeaderVue from "@/layout/component/HeaderVue.vue";
import "./style/reset.css"
import "./style/layout.css"
import './style/element-variables.scss'
import "@/utils/bus"
import "./permission"
import BaiduMap from 'vue-baidu-map'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import moment from 'moment'   //时间自动转化问题
Vue.config.productionTip = false
import 'swiper/dist/css/swiper.min.css'   //轮播图
import 'swiper/dist/js/swiper.min'

Vue.use(ElementUi)
Vue.component('HeaderVue', HeaderVue)
Vue.use(BaiduMap, {
  ak: '' // 这里填入你在百度地图开放平台申请的API Key
})
//全局过滤器  转换时间
Vue.filter('dateFmt', (input, formatString = "YYYY-MM-DD") => {
  //es5函数参数设置默认值
  //const lastFormatString = formatString || ''
  // moment(input) 把时间字符串转成时间对象
  // format(formatString) 把时间对象，按照指定格式，格式化成符合条件的字符串
  return moment(input).format(formatString)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
