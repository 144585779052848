import http from '@/utils/request'
// 获取轮播图列表接口
export function portalBannerList(query) {
  return http.get("/haip-biz/portalBanner/getBanner", query);
}
// 获取宣传视频接口
export const portalVideoList = function (params) {
  return http.get('/haip-biz/portalVideo/getVideo',params)
}
//获取新闻列表接口
export const getNewsListAPI = function (params,access_token) {
  return http.post('/apis/biz-server/cms/list', params)
}
//获取新闻详情接口
export const getInfoNews = function (params,access_token) {
  return http.get('/apis/biz-server/cms/info?cmsId='+ params)
}
// 查询字典数据列表
export const getDict = function (params) {
  return http.post('/apis/biz-server/dict/list', params)
}



// 版权部分保存接口
export const getInsert = function (params) {
  return http.post('/apis/biz-server/leads/insert', params)
}

// 投诉建议保存
export let getAdviceInsert;
getAdviceInsert = function (params) {
  return http.post("/haip-biz/leads/advice/insert", params)
};
// 获取行业列表
export const getTradeList = function (params) {
  return http.post('/apis/biz-server/dict/list', params)
}
//   查询专业课程接口（下拉列表）
export const courseCategory = function(params, access_token) {
  return http.post("/haip-biz/major-all/list", params)
};
//  获取省市
export const getAreaList = function (params) {
  return http.post('/apis/biz-server/area/list', params)
}

// 渠道管理动态获取
export const getChannelList = function(params) {
  return http.post("/haip-activity/channel/list", params)
};


//获取人数
export const getCountList = function (params) {
  return http.get('/apis/biz-server/leads/count', params)
}
//获取广告回调接口
export const advertisersCallback = function (params) {
  return http.get('/apis/biz-server/leads/wpscallback', {params})
}

//获取短信验证码
export const getCodeFun = function (params) {
  return http.post('/oauth/sms/send-identity-code', params)
}

//短信验证码验证是否准确
export const getVerifyCode = function (params) {
  return http.post('/oauth/sms/verify-code', params)
}
// 校友风采列表
export const getAlumniInformation = function (params) {
  return http.post('/haip-activity/alumnInformation/list', params)
}
//获取校友风采详情接口
export const getAlumnInformationInfo = function (params) {
  return http.post('/haip-activity/alumnInformation/infoNews', params)
}
//
