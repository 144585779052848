export default {
    state: {
        headerMenuList: [
            {"id": "1", "title": "首页", "path": "/home"},
            {
                "id": "2",
                "title": "学校概况",
                "path": "/schoolProfile",
                "children": [
                    {"id": "2-1", "title": "学校简介", "path": "/schoolProfile", "component": 'SchoolIntroduction'},
                    {"id": "2-2", "title": "学校历史", "path": "/schoolProfile", "component": 'SchoolHistory'},
                    {"id": "2-3", "title": "校长致辞", "path": "/schoolProfile", "component": 'PresidentSpeech'},
                    {"id": "2-4", "title": "学校荣誉", "path": "/schoolProfile", "component": 'SchoolHonors'},
                ]
            },
            {
                "id": "3",
                "title": "人才培养",
                "children": [
                    {"id": "3-1", "title": "本科教育", "path": "/undergraduateEdu", "component": 'UndergraduateVue'},
                    {
                        "id": "3-2",
                        "title": "硕士项目",
                        "path": "/eduProgram",
                        "children": [
                            {
                                id: '3-2-1',
                                value: "工商管理硕士",
                                path: "/masterEducation",
                                component: "MbaVue",
                            },
                            {
                                id: '3-2-2',
                                value: "工商管理硕士(精益管理方向)",
                                path: "/masterEducation",
                                component: "MbaLean",
                            },
                            {
                                id: '3-2-3',
                                value: "市场营销与经营管理硕士",
                                path: "/masterEducation",
                                component: "MmmVue",
                            },
                            {
                                id: '3-2-4',
                                value: "教育管理硕士",
                                path: "/masterEducation",
                                component: "MemVue",
                            },
                            {
                                id: '3-2-5',
                                value: "国际商务硕士",
                                path: "/masterEducation",
                                component: "MibmVue",
                            },
                            {
                                id: '3-2-6',
                                value: "应用心理学与管理硕士",
                                path: "/masterEducation",
                                component: "MapmVue",
                            },
                            {
                                id: '3-2-7',
                                value: "资产管理与金融硕士",
                                path: "/masterEducation",
                                component: "MafmVue",
                            },
                            {
                                id: '3-2-8',
                                value: "房地产经济与管理硕士",
                                path: "/masterEducation",
                                component: "MreemVue",
                            },
                            {
                                id: '3-2-9',
                                value: "数字化经营与管理硕士",
                                path: "/masterEducation",
                                component: "MdomVue",
                            },
                            {
                                id: '3-2-10',
                                value: "会计与管理硕士",
                                path: "/masterEducation",
                                component: "MamVue",
                            }, {
                                id: '3-2-11',
                                value: "健康管理硕士",
                                path: "/masterEducation",
                                component: "DgmVue",
                            }
                        ],
                    },
                    {"id": "3-3", "title": "博士项目", "path": "/eduProgramDr"}
                ]
            },
            {
                "id": "4", "title": "师资队伍",
                "children": [
                    {"id": "4-1", "title": "师资队伍", "path": "/teacherTroop", component: 'TeacherList'},
                ]
            },
            {
                "id": "5",
                "title": "校友风采",
                "children": [
                    {"id": "5-1", "title": "校友专访", "path": "/alumniStyle"},
                    {"id": "5-2", "title": "校友大事记", "path": "/alumniStyle"}
                ]
            },
            {
                "id": "6",
                "title": "新闻资讯",
                "children": [
                    {"id": "6-1", "title": "学校动态", "path": "/bbsNews"},
                    {"id": "6-2", "title": "DBA专访", "path": "/bbsNews"}
                ]
            },
            {
                "id": "7",
                "title": "招生就业",
                "children": [
                    {"id": "7-1", "title": "直营中心", "path": "/marketPage", "component": 'DomesticTeachers'},
                    {"id": "7-2", "title": "授权合作", "path": "/marketPage", "component": 'AuthorizedCooperation'},
                    {"id": "7-3", "title": "合作咨询", "path": "/marketPage", "component": 'CooperationVue'},
                    {"id": "7-4", "title": "企业招聘", "path": "/marketPage", "component": 'EnterpriseVue'}
                ]
            },
            {"id": "8", "title": "校友会", "path": "/alumni"},
            {
                "id": "9", "title": "实践与创新",
                "children": [
                    {"id": "9-1", "title": "私董会", "path": "/innovation", "component": 'PrivateBoard'},
                    {"id": "9-2", "title": "企业管理研究会", "path": "/innovation", "component": 'EnterpriseManagement'},
                    {"id": "9-3", "title": "3A精益管理学院", "path": "/innovation", "component": 'LeanManagement'},
                    {"id": "9-4", "title": "BBS·AI智创研究院", "path": "/innovation", "component": 'AiIntelligent'},
                ]
            }
        ],
        selectMenu: {},
        // 根据ID获取菜单项（注意！所有菜单项ID必须唯一）
        getMenuById(state, id) {
            // 根据id 递归查找 headerMenuList
            for (let i = 0; i < state.headerMenuList.length; i++) {
                let item = state.headerMenuList[i];
                if (item.id === id) {
                    return item;
                }
                if (item.children) {
                    for (let j = 0; j < item.children.length; j++) {
                        let child = item.children[j];
                        if (child.id === id) {
                            return child;
                        }
                    }
                }
            }
            return state.headerMenuList[0];
        }
    },
    mutations: {
        // 变更选中的菜单ID
        setSelectMenu(state, id) {
            state.selectMenu = state.getMenuById(state, id);
        }
    },
    actions: {}
}
